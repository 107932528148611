@import "../../../styles/tools";

.container {
    flex: 1;
    min-width: 215px;
    max-width: 350px;
    width: 100%;
    display: block;
    background: none;
    border: none;
    outline: none;
    appearance: none;
    padding: 0;

    &:not(:last-child) {
        margin-right: 9px;
    }
}

.title {
    padding: 0 15px;
    color: #131F27;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 10px;
    white-space: nowrap;
}

.bottom {
    display: flex;
    align-items: center;
}

.line {
    background: #DFDFDF;
    flex: 1;
    height: 2px;
    transition: all 0.3s ease-in-out;
}

.dot {
    background: #DFDFDF;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-left: 9px;
    margin-right: 8px;
    transition: all 0.3s ease-in-out;

    &::before {
        display: block;
        content: ' ';
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 2px solid #DFDFDF;
        box-sizing: border-box;
        transform: translate(-4px, -4px) scale(0.0);
        transition: all 0.3s ease-in-out;
    }
}

.container--default {
    .title {
        color: #656D72;
        font-weight: 400;
    }

    .line, .dot {
        background: #DFDFDF;
    }
}

.container--active {
    .line, .dot {
        background-color: #F2C94C;
    }

    .dot::before {
        border-color: #F2C94C;
        transform: translate(-4px, -4px) scale(1.0);
    }
}

.container--completed {
    .title {
        color: $color-green;
    }

    .line, .dot {
        background-color: $color-green;
    }
}

.container--clickable {
    cursor: pointer;
    
    &:hover {
        .title {
            text-decoration: underline;
            color: #000;
        }
    }
}

.container--light {
    min-width: unset;
    width: unset;
    margin-right: 0;
    flex: unset;

    .line {
        width: 40px;
        flex: unset;
    }

    .dot {
        margin-left: 0;
    }
}