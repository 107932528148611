@import "~@ui/styles/tools";

.wrapper {
    display: grid;
    place-items: center;
    height: 100%;
}

.container {
    width: 100%;
    max-width: 600px;
}

.title {
    text-align: center;
    margin-bottom: 80px;
    font-size: 32px;
    line-height: 32px;
}

.footer {
    margin-top: 80px;
}

.policies {
    color: $color-body;
    text-align: center;
}

.app-badges {
    display: flex;
    gap: 20px;
}

.change-phone {
    display: flex;
    background-color: transparent;
    color: $color-primary;
    
    border: none;
    box-shadow: none;
    outline: none;
    margin: 0 auto 20px;
    padding: 0;

    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.3px;
    transition: color 0.2s ease-in;

    cursor: pointer;

    &:hover {
        color: #165a4b;
    }
}

@include media-tablet-portrait {
    .app-badges {
        display: grid;
        grid-template-columns: 170px 170px;
        justify-content: center;
        gap: 10px;
    }
}

@include media-mobile {
    .wrapper {
        justify-content: flex-start;
    }

    .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 0;
    }

    .footer {
        margin-bottom: 10px;
    }

    .policies {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
    }
}

@include media-mobile-xs {
    .app-badges {
        grid-template-columns: 1fr 1fr;
        border-top: none;
        justify-content: start;
    }
}