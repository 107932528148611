@import "~@ui/styles/tools";

.container {
    display: flex;
    align-items: flex-start;
    min-height: 120px;
    justify-content: space-between;
    gap: 20px;
    max-width: 450px;
    margin: 0 auto;
}

.start-button {
    height: 54px;
    margin-top: -2px;
}

.start-button__text {
    font-size: 18px;
}

.footer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.input {
    width: 100%;
}

@include media-mobile {
    .input {
        min-height: 100px;
    }

    .container {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
    }
}