@import "../../../styles/tools";

.input-wrapper {
    display: inline-block;
}

input[type].input {
    display: block;
    width: 100%;
    height: 80px;
    margin-top: -80px;
    // We still need to keep this values at low bound, but not zero to keep component working well
    opacity: 0.01;
    font-size: 1px;
    color: transparent;
    background: transparent;
    border: none;
}

.label__error {
    font-size: $font-size--xsmall;
    line-height: $font-size--xsmall;
    font-weight: 300;
    letter-spacing: 0.3px;
    display: block;
    padding: 5px 10px;
    color: $color-error;
}

.slots {
    display: flex;
    flex-direction: row;
    gap: 8px;
    pointer-events: none;
}

.slot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 80px;
    border-radius: $border-radius;
    border: 1px solid $color-grey;
    background: $color-off-white;
    padding: 10px;
    font-size: 24px;
    line-height: 32px;
    color: $color-title-active;
    font-weight: 600;
}

.slot__caret {
    display: inline-block;
    width: 1px;
    height: 32px;
    background: $color-title-active;
    margin-left: -1px;
    transform: translateX(5px);
    animation: blinker 1s step-end infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.slot__secure-dot {
    width: 16px;
    height: 16px;
    background: $color-title-active;
    border-radius: 16px;
}

.slot--focus {
    border-color: $color-primary;
}

@mixin colorize($color) {
    border-color: $color;
    color: $color;

    .slot__secure-dot {
        background: $color;
    }
}

.slot--status-success {
    @include colorize($color-success);
}

.slot--status-error {
    @include colorize($color-error);
}

.slot--disabled {
    @include colorize($color-grey-dark);

    &.slot--status-success {
        @include colorize(rgba($color-success, 40%));
    }

    &.slot--status-error {
        @include colorize(rgba($color-error, 40%));
    }
}