@import "@frontend/jetlend-web-ui/src/styles/tools";


.back {
    margin-left: -25px;

    &__text {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        height: 16px;
    }

    &__icon {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        margin-right: 8px;
    }
}

.container {
    display: flex;
    align-items: center;
}

.link {
    padding: 11px 25px 10px 0px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

.container, .link {
    &:hover {
        .back__text {
            color: darken(#FFF, 20%);
        }

        .back__icon {
            filter: brightness(0.8);
        }
    }

    &:active {
        .back__text {
            color: darken(#FFF, 30%);
        }

        .back__icon {
            filter: brightness(0.7);
        }
    }
}