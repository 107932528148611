@import "@frontend/jetlend-web-ui/src/styles/tools";


.input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
}
    
    .label {
        color: $color-title-active;
        font-size: $font-size--xlarge;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 28px */
        margin-bottom: 12px;

        @include media-mobile {
            font-size: $font-size--small;
        }
    }

    .field {
        width: 100%;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .remind {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
        }

        a {
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%; 

            @include media-mobile {
                font-size: $font-size--xsmall;
            }
        }
    }

    .button {
        width: 100%;
    }

    .legal {
        color: $color-grey--dark;

        /* fonts/Medium 16 */
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */

        max-width: 346px;

        flex-shrink: 0;

        margin-top: 32px;

        a {
            color: $color-primary;
        }

        @include media-mobile {
            font-size: $font-size--xsmall;
        }
    }

    .login {
        align-self: center;

        margin-top: 32px;

        a {
            color: $color-grey--dark;
            text-decoration: underline;

            @include media-mobile {
                font-size: $font-size--xsmall;
            }
        }
    }

.button-container {
    width: 100%;
    margin: 0;
    padding: 0;

}


.back {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;

    &__text {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    &__icon {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
    }
}
