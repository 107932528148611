@import "../../styles/tools";

.sms-dialog {
    &__confirmation-content {
        margin-bottom: 16px;
    }
}

.container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.code-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.phone-text {
    width: 290px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    letter-spacing: 0.3px;
    color: $color-body;
    margin-bottom: 24px;
}

.phone {
    color: $color-secondary;
}