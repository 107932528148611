@import "../../styles/tools";

.container {
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex: 1;
  margin-right: 15px;
}

.slider-container {
  display: flex;
  align-items: center;
  align-content: center;
}

.title {
  color: #1E202180;
  margin-top: 8px;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 16px;
}

.step {
  flex: 1;
  width: 100%;
  display: block;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  padding: 0;
  height: 5px;
  border-radius: 12px;

  &--default {
    background: #DFDFDF;
  }

  &--active {
    background-color: #F2C94C;
  }

  &--completed {
    background-color: $color-green;
  }

  &--clickable {
    cursor: pointer;
    background-color: #00A884;
  }
}