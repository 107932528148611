.container {
    padding: 20px 30px 0;

    background: #fff;
    border-radius: 5px 5px 0 0;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;

    &-mobile {
        justify-content: center;
    }
}

.scroll-track, .scroll-thumb {
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
}

.scroll-container {
    min-height: 60px;
}

.scroll-track {
    background: #CCC !important;
    height: 5px !important;
}

.scroll-thumb {
    background: #707070 !important;
}

.container:hover {
    .scroll-track, .scroll-thumb {
        opacity: 1;
    }
}

.container--light {
    padding: 0;
    height: 12px;
}